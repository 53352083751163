<template>
    <div>
     <div v-if="pageLoader" class="loading">
            <div class="load_img">
                <!-- <img src="../../../public/assets/images/loader.gif" alt="loader" width="100"> -->
            </div>
        </div>
        <div v-if="Object.keys(faqslist).length > 0">
            <section class="blockElement themeBG">
                <div class="container">
                    <div class="row diraction">
                        <div class="col-12 col-lg-4 mt-3">
                            <nav class="mb-3 mb-lg-0">
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item">
                                        <router-link :to="entityPath+'/help-centre'" class="themecolor"><h6 class="mb-0 semibold">Home</h6></router-link>
                                    </li>
                                    <li class="breadcrumb-item" aria-current="page">                                
                                        <router-link :to="entityName1+'/help-centre/'+categorySlug" class="f-18">
                                            
                                                {{categories.categoryName}}
                                            
                                            
                                        
                                        </router-link>
                                    </li>                                
                                </ol>
                            </nav>
                        </div>                    
                        <div class="col-12 col-lg-8">
                            <app-AutoSearch :pageName="'articlesDetails'" :list="faqslist"></app-AutoSearch>                        
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement faqBlock lightgreybg"  v-if="categories != undefined && Object.keys(categories).length > 0">
                <div class="container">
                    <div class="mb-4 mb-lg-5">
                        <h2 class="mb-0">{{categories.categoryName}}</h2>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="leftSidebar lightgrayBG">
                                <h4 class="secondary mb-0 pb-4">Articles in this section</h4>                            
                                <ul class="scrollbar listdDot">    
                                    <li v-for="(value, name, index) in article_listing"  v-bind:class="( value.articleSlug == articleSlug ) ? 'active' : '' " v-on:click="articlesInfo(value.articleSlug)" :key="index">
                                        <router-link :to="entityName1+'/help-centre/'+categorySlug+'/'+value.articleSlug" class="hvr-grow-shadow">
                                            <chevron-right-icon></chevron-right-icon>
                                            {{value.articleName}}
                                        </router-link>
                                    </li>                            
                                    
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-8">
                            <div class="right_sidebar mt-3 mt-lg-0">                  
                                <h4 class="secondary mb-4">{{categories.articleName}}</h4>
                                <div class="supportUser d-flex spacebetween align-items-center my-4">
                                    <div class="article-author d-flex align-items-center">
                                        <div class="avatar article-avatar flex-shrink-0">
                                            <span class="icon-agent"></span>
                                            <!-- <img src="../../../public/assets/images/default_avatar.webp" alt="" class="user-avatar"> -->
                                        </div>
                                        <div class="article-meta flex-grow-1 ms-3">
                                            <h6>Capital Wallet Support</h6>
                                            <ul class="meta-group d-flex">
                                                <li class="meta-data"><time>{{getHumanDate()}}</time></li>
                                                <li class="meta-data">&nbsp; Updated</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="article-body">
                                    <p v-html="categories.description"></p>
                                </div>
                                <div class="socialIcon mt-5">
                                   <div class="social_share">
                                        <span>
                                            <a href="https://www.facebook.com/capitalwalletau/" target="_blank" class="fb">
                                                <facebook-icon></facebook-icon>
                                            </a>
                                        </span>
                                        <span>
                                            <a href="https://twitter.com/Capitalwallet_" target="_blank" class="tw">
                                                <twitter-icon></twitter-icon>
                                            </a>
                                        </span>
                                        <span>
                                            <a href="https://www.instagram.com/capitalwallet/" target="_blank" class="lk">
                                                <instagram-icon></instagram-icon>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mt-4 border-custom mx-0">
                                    <div class="col-12">
                                        <div class="article-votes text-center border-top border-bottom py-4">
                                            <span class="article-votes-question">Was this article helpful?</span>
                                            <div class="d-flex align-items-center justify-content-center mt-3" role="radiogroup">
                                                <a href="javascript:void(0)" role="radio" rel="nofollow" class="customLg d-flex align-items-center green me-4">
                                                    <vue-feather size="16px" class="me-1" type="thumbs-up"></vue-feather> Yes
                                                </a>
                                                <a href="javascript:void(0)" role="radio" rel="nofollow" class="customLg d-flex align-items-center red">
                                                    <vue-feather size="16px" class="me-1" type="thumbs-down"></vue-feather> No
                                                </a>
                                            </div>
                                            <br>
                                            <small class="article-votes-count mt-3">
                                            <span class="article-vote-label">0 out of 0 found this helpful</span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                 <div class="row mt-5 mx-0">
                                    <div class="col-12 col-md-6">
                                        <section v-if="Object.keys(recentViewedArticles).length > 0">
                                            <h4 class="mb-3 mb-lg-4">Recently viewed articles</h4>
                                            <ul class="mb-0" v-for="(value, name, index) in recentViewedArticles.slice(0,3)" :key="index" v-show="value.articleSlug != articleSlug">

                                                <li v-on:click="articlesInfo(value.articleSlug)" class="semibold mb-2 mb-lg-3">
                                                    <router-link :to="entityName1+'/help-centre/'+value.categorySlug+'/'+value.articleSlug" rel="nofollow" class="f-18">{{value.articleSlug}}</router-link>
                                                </li>
                                            </ul>
                                        </section>
                                    </div>
                                    <div class="col-12 col-md-6" v-if="Object.keys(categories).length > 0">
                                        <section>
                                            <h4 class="mb-3 mb-lg-4 mt-2 mt-md-0">Related articles{{categories[categorySlug]}}</h4>                                        
                                            <ul class="mb-0" v-for="(value, name, index) in article_listing.slice(0,3)" :key="index" v-show="categorySlug != '' && value.articleSlug != articleSlug">
                                                <li v-on:click="articlesInfo(value.articleSlug)" class="semibold mb-2 mb-lg-3">
                                                    <router-link :to="entityName1+'/help-centre/'+categorySlug+'/'+value.articleSlug" rel="nofollow" class="f-18">{{value.articleName}}</router-link>
                                                </li>
                                            </ul>
                                        </section>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
    //import { ChevronRightIcon, CheckIcon, XIcon,FacebookIcon, TwitterIcon,InstagramIcon  } from 'vue-feather-icons'
    // import { finViewMixins } from '@/plugins/finView'
     import moment from 'moment' 
    import autoSearch from '@/components/shared/auto_search'
    import commonAllmixins from "@/plugins/commonAll";

    export default {
        name: 'HOMES',        
        mixins:[commonAllmixins],
        data () {
            return {   
               "categorySlug" : this.$route.params.category,
                "categoryName" : "",
                "articleSlug" : "",                              
                "recentViewedArticles" : [],
                "article_listing" : [],
                //"category_bread_crumb" :'',
                pageLoader : false,
                entityName1 : ''
            }
        },
        methods: {
            getHumanDate : function () {
                return moment().format('DD/MM/YYYY');
            },
           articlesInfo(slug){                
                this.categorySlug = this.$route.params.category                
                this.articleSlug = slug                               
                //this.categoryName = this.categories.categoryName                
                if(typeof localStorage.getItem("recentViewed") != 'undefined' && localStorage.getItem("recentViewed") != null && localStorage.getItem("recentViewed") != ''){                    
                    let localStorageObj = JSON.parse(localStorage.getItem("recentViewed"))                                        
                    if(localStorageObj.length > 4){
                        localStorageObj.shift()
                        this.recentViewedArticles.shift()
                        localStorage.setItem("recentViewed", JSON.stringify(localStorageObj))   
                    }
                }
                this.recentViewedArticles.push({
                    "articleSlug" :  this.articleSlug,
                    "categorySlug" :  this.categorySlug,
                })
                localStorage.setItem("recentViewed", JSON.stringify(this.recentViewedArticles)) 
                this.article_listing = this.faqslist.dataArr[this.categorySlug]                  
            } ,
            call_faqs(){
                let sendData = {
                    'globalParams':{'page' : 'detail'},
                    'localParams':{'parameter':this}
                }
                this._runGetFaqsList(sendData)
            },
            
        },
        computed: {
            // categories: function () {
            //     try{                                        
            //         return this.$store.getters['GET_CATEGORIES_LISTING'].dataArr
            //     }catch(e){
            //         return []
            //     }
            // }
            categories: function () {
                try{    
                        let categories_listing = this.faqslist.dataArr 
                        if(Object.keys(categories_listing).length > 0){
                            //this.article_listing = categories_listing[this.categorySlug]
                            //this.category_bread_crumb = categories_listing
                            let returnVal = {}
                            for(let i= 0 ; i<=categories_listing[this.categorySlug].length-1 ; i++){

                                if(categories_listing[this.categorySlug][i].articleSlug == this.articleSlug){
                                    returnVal = categories_listing[this.categorySlug][i]
                                }
                            }
                            return returnVal
                        }
                    
                    else{
                        return {}
                                         
                    //return this.$store.getters['GET_CATEGORIES_LISTING']                
                    }
                }catch(e){
                    console.log('e',e)
                    return {}
                }
            },   
        },        
        created() {

            if(this.$store.getters['GET_FAQS_LIST'] != ''){
                this.loaderFunction = '0'
            }


            if(typeof localStorage.getItem("recentViewed") != 'undefined' && localStorage.getItem("recentViewed") != null && localStorage.getItem("recentViewed") != ''){
                this.recentViewedArticles = JSON.parse(localStorage.getItem("recentViewed"))
            }

            //this.articlesInfo(this.$route.params.article)                



            /*this._runGetArticelsList({
                "parameters": this,
                "loaderFunction" : this.loaderFunction
            })*/ // GET Articels
            
            this.call_faqs()    
            this.entityName1 = ((this.entityName == 'global')?'/global' : '/au');      
            
        },        
        mounted() { 
          window.scroll(0,0)
        },
        components: {            
            // ChevronRightIcon,
            // CheckIcon,
            // XIcon,
            // TwitterIcon,
            // FacebookIcon,
            // InstagramIcon,
            appAutoSearch: autoSearch,  
        }
    }
</script>
